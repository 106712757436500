.product-grid-item {
    &__image-wrapper {
        .lazyload-wrapper {
            padding-bottom: 100%;
        }
    }

    &__name-link {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @include mq($screen-m) {
            -webkit-line-clamp: 2;
        }
    }

    &__link {
        &:after {
            content: "";
            position: absolute;
            background: $gray-light;
            height: 1px;
            width: 100%;
            top: 100%;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
        }
    }

    &__details {
        margin: $spacer--medium 0;
    }

    &__price {
        @include mq($screen-m) {
            width: 50%;
        }

        .price__value {
            font-size: $font-size-base;

            @include mq($screen-m) {
                font-size: $font-size-large;
            }
        }
    }

    &__actions {
        @include mq($screen-m) {
            width: 50%;
        }
    }

    &__primary-action {
        display: flex;
    }

    &__qty {
        margin: 0;
    }

    &__qty-input {
        @extend .cart-list-item__input;
        width: 60px;
        padding: 0 $spacer;
    }

    &__details-bottom {
        column-gap: $spacer;
        flex-wrap: nowrap;
    }
}
