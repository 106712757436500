$main-menu__gray-dark       : #3d3d3d;
$main-menu__gray            : #575757;
$main-menu__gray-light      : #bdbdbd;
$main-menu__gray-lighter    : #f5f5f5;

$main-menu__indent-xs       : $spacer--extra-small;
$main-menu__indent-s        : $spacer;
$main-menu__indent-base     : $spacer--medium;
$main-menu__indent-m        : $spacer--semi-medium;
$main-menu__indent-l        : $spacer--large;

$main-menu__font-family     : $font-family-sans-serif;
$main-menu__font-family-bold: $font-family-sans-serif;

$main-menu__background-color: $main-menu__gray;
$main-menu__border-color    : $gray;

$main-menu__inner-list__background      : $main-menu__gray;
$main-menu__inner-item__padding         : $spacer--extra-small $spacer;
$main-menu__inner-item__background-color: $main-menu__gray-dark;
$main-menu__inner-item__color           : $main-menu__gray;
$main-menu__inner-item__color-desktop   : $white;
$main-menu__inner-item__color-hover     : #fd5e5e;
$main-menu__transition                  : transform 0.25s ease-in-out;
.main-menu {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    color: $white;

    @include mq($screen-l) {
        display: block;
        padding-right: $main-menu__indent-base;
        padding-left: $main-menu__indent-base;
        background-color: $main-menu__gray-lighter;
    }

    &__list {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid $main-menu__border-color;

        @include mq($screen-l) {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            border-bottom: none;
        }

        .main-menu__item:first-child {
            .main-menu__link {
                @include mq($screen-l) {
                    padding-left: 0;
                }
            }
        }
    }

    &__item {
        margin: 0;
        padding: 0;
        border-top: 1px solid $main-menu__border-color;
        cursor: pointer;

        @include mq($screen-l) {
            border-top: 0;
        }

        &--parent {
            &:hover,
            &:focus {
                & > .main-menu__inner-list {
                    z-index: 9999;
                    transform: translateY(0%);
                    visibility: visible;
                    opacity: 1;
                    transition-delay: 0.5s, 0.5s, 0.5s;
                }

                .main-menu__link {
                    color: $main-menu__inner-item__color-hover;
                }

                @include mq($screen-l) {
                    & > .main-menu__link .main-menu__icon {
                        transform: rotate(180deg);
                        transition: $main-menu__transition;
                    }
                }
            }

            .main-menu__link {
                position: relative;
                padding-right: 45px;
            }
        }

        &--open {
            & > .main-menu__inner-list {
                display: block;

                & > .main-menu__inner-item > .main-menu__inner-list {
                    display: block;
                }
            }
            & > .main-menu__link .main-menu__icon {
                transform: rotate(180deg);
                transition: $main-menu__transition;
            }
        }

        &--right {
            @include mq($screen-l) {
                margin-left: auto;
            }
        }

        &--smaller {
            & > .main-menu__link {
                font-size: $font-size-medium;
                @include mq($screen-l) {
                    padding: 20px $main-menu__indent-base;
                    font-size: $font-size-small;
                }
            }
        }

        & > .main-menu__link {
            text-transform: uppercase;
        }
    }

    &__link {
        position: relative;
        display: block;
        padding: $spacer--small $main-menu__indent-base;
        color: $black;
        font-weight: $font-weight-bold;
        font-size: $font-size-medium;
        font-family: $font-family-sans-serif;

        @include mq($screen-l) {
            padding: $spacer--medium $spacer--semi-medium;
            font-size: $font-size-large;
            transition: all 0.2s ease-in-out;
        }

        &:visited {
            color: $black;
        }

        &:focus,
        &:hover {
            color: $main-menu__inner-item__color-hover;
            text-decoration: none;
        }

        &.current {
            padding-left: $main-menu__indent-base - $spacer;
            color: $red;
            border-color: $red;
            border-style: solid;
            border-width: 0 0 0 $spacer;

            @include mq($screen-l) {
                padding-left: $main-menu__indent-base;
                border-color: $red;
                border-style: solid;
                border-width: 0 0 3px;
            }
        }

        .main-menu__item--parent & {
            padding-right: 21px;
        }
    }

    &__inner-list {
        display: none;
        margin: 0;
        padding: 0;
        list-style: none;

        @include mq($screen-l) {
            display: block;
            font-size: $font-size-base - 1px;
        }

        &--level1 {
            @include mq($screen-l) {
                position: absolute;
                top: 100%;
                right: $main-menu__indent-base;
                left: $main-menu__indent-base;
                z-index: 9999;
                display: flex;
                width: auto;
                padding: 15px 0;
                line-height: 1.5;
                list-style: none;
                background: $main-menu__inner-list__background;
                border: 1px solid $main-menu__gray;
                box-shadow: none;
                transform: translateY(-2em);
                visibility: hidden;
                opacity: 0;
                transition: all 0.2s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
            }
        }

        .main-menu__inner-item--column & {
            display: block;
            @include mq($screen-l) {
                margin-bottom: $spacer;
            }
        }
    }

    &__inner-item {
        position: relative;
        flex: 1;
        margin: 0;
        padding: 0;

        &--all {
            display: none;
        }

        &--column {
            min-width: 230px;
            border-right: 1px solid $main-menu__gray-lighter;

            @include mq($screen-l) {
                padding-left: $main-menu__indent-m;
            }

            &:last-child {
                border-right: none;
            }

            & > a {
                display: none;
            }
        }

        &--level2 {
            & > .main-menu__inner-link {
                font-weight: bold;
                font-family: $main-menu__font-family-bold;
                text-transform: uppercase;

                @include mq($screen-l) {
                    margin-top: $main-menu__indent-m;
                    margin-right: $main-menu__indent-m;
                    margin-bottom: $main-menu__indent-xs;
                    padding: $main-menu__inner-item__padding;
                    color: $main-menu__gray-light;
                    background-color: $main-menu__inner-item__background-color;
                }
                &:visited {
                    @include mq($screen-l) {
                        color: $main-menu__gray-light;
                    }
                }

                &:hover,
                &:active {
                    @include mq($screen-l) {
                        color: $white;
                    }
                }
            }
        }
        &--parent {
            & .main-menu__inner-list {
                display: none;

                @include mq($screen-l) {
                    display: block;

                    &:last-child {
                        margin-bottom: $main-menu__indent-base;
                    }
                }
            }
        }
        &--open {
            & > .main-menu__inner-list {
                display: block;
            }
            & > .main-menu__inner-link {
                .main-menu__icon {
                    transform: rotate(180deg);
                    transition: $main-menu__transition;
                }
            }
        }
    }

    &__inner-link {
        display: block;
        padding: $spacer--small 56px $main-menu__indent-base $spacer--small;
        color: $main-menu__inner-item__color;

        @include mq($screen-l) {
            padding: $main-menu__inner-item__padding;
            color: $main-menu__inner-item__color-desktop;
        }

        &:visited {
            color: $main-menu__inner-item__color;

            @include mq($screen-l) {
                color: $main-menu__inner-item__color-desktop;
            }
        }

        &:active,
        &:hover {
            color: $main-menu__inner-item__color;
            text-decoration: none;

            @include mq($screen-l) {
                color: $main-menu__inner-item__color-hover;
            }
        }

        .main-menu__inner-item--level3 & {
            &:active,
            &:hover {
                @include mq($screen-l) {
                    color: $main-menu__inner-item__color-hover;
                }
            }

            @include mq($screen-l) {
                padding: $main-menu__inner-item__padding;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 7px;
        right: 23px;
        width: 30px;
        height: 30px;
        transition: $main-menu__transition;
        fill: $black;

        @include mq($screen-l) {
            top: 16px;
            right: -2px;
            width: 25px;
            height: 25px;
        }

        .main-menu__inner-item--parent & {
            top: 17px;
            right: 25px;
            display: block;
            width: 25px;
            height: 25px;

            @include mq($screen-l) {
                display: none;
            }
        }
    }

    &--desktop {
        display: none;
        @include mq($screen-l) {
            display: block;
        }
    }

    &--mobile {
        .main-menu__item--parent:first-child {
            border: none;
        }
        .main-menu__inner-item--level1 {
            border: none;
        }
    }
}
