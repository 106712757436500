.search-form {
    &__label {
        color: $gray-lighter;
        font-weight: $font-weight-light;
    }
    // specificity issue
    input.search-form__input {
        color: $gray-lighter;
        background-color: $color-secondary;
        border: $border-base;
        &:focus-within {
            color: $gray-lighter;
            &::placeholder {
                color: $gray-lighter;
            }
        }
        &::placeholder {
            color: $gray-lighter;
            font-weight: $font-weight-light;
        }
    }
    &__button {
        background: $red;
        border: none;
        border-right: $border-base;
        border-left: $border-base;
        &:disabled {
            background: $red;
            border: none;
            border-right: $border-base;
            border-left: $border-base;
        }
        .button__icon {
            fill: $gray-lighter !important;
        }
    }
}
