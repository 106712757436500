//  styles copied from 'vendor/bsscommerce/module-fast-order/view/frontend/web/css/source/_module.less'

$color_1: #e02b27;
$color_2: #000;
$color_3: #ff0000;
$color_4: #3399cc;
$color_5: red;
$color_6: #646464;
$color_7: #7f7f7f;
$color_8: #333;
$color_9: rgb(148, 148, 148);
$color_10: black;
$color_11: rgb(40, 40, 40);
$color_12: white;
$background_color_1: #fff;
$background_color_2: #e7bcb6;
$background_color_3: #ffffff;
$background_color_4: rgba(53, 53, 53, 0.639216);
$background_color_5: #fff;
$border_color_1: #fff transparent transparent transparent;
$border_color_2: #adadad transparent transparent transparent;

.bss-content-option-product {
    @extend .modal;
}

.bss-fastorder-autocomplete {
    .content-search-fastorder {
        max-height: 567px;
        overflow: auto;
        position: absolute;
        width: 98.7%;
        max-width: 360px;
        text-align: -webkit-auto;
        z-index: 1;
        margin-left: 0;

        .bss-row-suggest {
            width: 100%;
        }

        ul {
            max-height: 430px;
            overflow: auto;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #ffffff;
        }

        &::-webkit-scrollbar {
            width: 12px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;

            li {
                cursor: pointer;
                padding: 10px;
                margin-bottom: 0;
                border-bottom: 1px solid #f9f9f9;

                &.bss-product-selected {
                    background: #f9f9f9;
                }

                a {
                    text-decoration: none;
                    .bss-product-name {
                        color: #111;
                    }
                }

                img {
                    max-width: 100%;
                }

                .bss-product-price {
                    text-align: left;
                    margin-top: 5px;

                    .bss-price {
                        font-weight: 500;
                        color: black;

                        &:after {
                            clear: both;
                            content: "";
                        }
                    }

                    .fastorder-tax {
                        color: #787878;
                        font-weight: 400;
                    }
                }

                .bss-product-sku {
                    strong {
                        font-weight: 100;
                    }

                    .type {
                        &:after {
                            content: ":";
                        }
                    }

                    .bss-ref-part {
                        color: #787878;
                        font-weight: 100;
                    }
                }

                .bss-product-info {
                    display: table;
                    width: 100%;

                    .bss-highlight {
                        font-weight: bold;
                    }

                    .bss-info-left {
                        width: 20%;
                        display: table-cell;
                        vertical-align: top;
                    }

                    .bss-info-right {
                        width: 80%;
                        display: table-cell;
                        vertical-align: top;
                        box-sizing: border-box;
                        padding-left: 15px;
                        text-align: left;
                    }
                }
            }
        }
    }
}

.cms-fast-order {
    .loading-mask {
        background: rgba(209, 209, 209, 0.5);
    }

    #bss-content-option-product {
        max-width: 490px;
        max-height: 100%;
        position: fixed;
        box-sizing: border-box;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin-left: 0;
        margin-top: 0;
        box-shadow: none;

        .bss-box-button {
            margin-top: 0;
            padding-top: 5px;
        }

        .bss-swatch-attribute {
            text-align: left;

            .bss-swatch-attribute-label {
                &:after {
                    content: "*";
                    color: #f00;
                    position: relative;
                    font-weight: bold;
                    font-size: 1em;
                }
            }

            &.size,
            &.color {
                .bss-swatch-attribute-label {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .price-box {
            .final-price {
                .price {
                    color: $color_3;
                    font-weight: 600;
                }
            }

            .price-including-tax {
                .price {
                    color: $color_3;
                    font-weight: 600;
                }
            }

            .excluding-tax {
                .price {
                    font-weight: 600;
                }
            }
        }

        .product-name {
            color: #111;
        }

        .photo {
            max-height: 150px;
            min-height: 141px;
        }

        .next-previous-button {
            .next-previous {
                background-color: transparent;
                border: none;
                box-shadow: none;

                &.next {
                    background-position: -42px 30px;
                }

                &.previous {
                    background-position: -22px 30px;
                }
            }
        }
    }

    .bss-fastorder-multiple-form {
        tbody {
            tr {
                td {
                    &.bss-fastorder-row-edit-delete {
                        display: table-cell;
                        position: relative;

                        @include mq($max-screen: $screen-m) {
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                        }

                        .bss-fastorder-row-edit,
                        .bss-fastorder-row-delete {
                            display: table-cell;
                            vertical-align: middle;
                            position: relative;
                            top: 0;

                            button {
                                padding: 1px 8px;
                                border: none;
                                background-color: transparent;
                                box-shadow: none;
                                margin: 0 2px;
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .bss-product-baseprice {
        > ul {
            list-style: none;
        }
    }
}

@include mq($screen-l) {
    .cms-fast-order {
        #bss-content-option-product {
            &.bss-configurable-grid-view-popup {
                max-height: 864px;

                .photo {
                    max-height: 214px;
                }
            }

            #bss-fastorder-form-option {
                #bss_configurablegridview {
                    max-height: 300px;
                }
            }
        }
    }
}

@include mq($screen-m) {
    #bss-fastorder-form {
        table {
            thead {
                .bss-fastorder-row {
                    display: table-row;
                }
            }
        }
    }

    #bss-fastorder-form {
        .bss-fastorder-autocomplete {
            .content-search-fastorder {
                width: 100%;
            }
        }
    }

    .cms-fast-order {
        #bss-content-option-product {
            overflow-x: auto !important;

            #bss-fastorder-form-option {
                .bss-swatch-attribute {
                    padding: 20px 0;
                }

                .bss-swatch-attribute-options {
                    width: 77%;

                    .bss-swatch-option {
                        width: 42px;
                        height: 42px;
                        padding: 10px 0 0 !important;

                        .reset-configurablegridview {
                            top: -10%;
                        }
                    }
                }

                .bss-swatch-attribute-selected-option {
                    padding-left: 0;
                }

                .bss-box-button {
                    padding-bottom: 9px;
                }
            }
        }
    }

    .cms-fast-order {
        .desktop-tfoot {
            display: none !important;
        }

        .bss-grouped-popup {
            table {
                &.grouped {
                    .item {
                        padding: 13px 10px 13px 10px !important;
                    }
                }
            }
        }
    }
}

@include mq($screen-s) {
    .cms-fast-order {
        #bss-content-option-product {
            max-height: 567px;
        }
    }
}

#bss-content-option-product {
    position: fixed;
    left: 50%;
    top: 30%;
    width: 380px;
    margin-left: -150px;
    margin-top: -100px;
    min-height: 100px;
    max-height: 420px;
    background-color: $background_color_3;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 10px -5px #999999;
    overflow-y: auto !important;
    z-index: 9999;
    border: 1px solid #e6e6e6 !important;

    #pagePopup {
        display: none;
        position: absolute;
        top: 30px;
        left: 30px;
        // .lib-font-size(15);
        color: #111;
        font-weight: 600;
    }

    .bss-product-info {
        position: relative;
        padding: 20px 20px 0;
    }

    #bss-fastorder-form-option {
        .bss-swatch-attribute-options {
            display: inline-table;
            vertical-align: middle;
            margin-top: 0;
            width: 80%;
            text-align: left;

            .bss-swatch-option {
                width: 48px;
                height: 48px;
                padding: 13px 0 0 !important;
                border-radius: 50%;
                box-sizing: border-box;
                border: none;
                margin-right: 1px;
            }
        }

        .bss-swatch-attribute {
            border-top: 1px solid #f9f9f9;
            padding: 20px 30px 17px;

            &.color {
                border-bottom: 1px solid #f9f9f9;
            }
        }

        .bss-swatch-attribute-label {
            display: inline-table;
            vertical-align: middle;
            text-transform: uppercase;
            font-weight: 600;
            width: 15%;
            padding-right: 5px;
        }

        .control {
            ul {
                padding: 0;
                margin-bottom: 0;

                li {
                    list-style: none;
                }
            }
        }

        #bss_configurablegridview {
            max-height: 215px;
            margin-bottom: 0;
            border-bottom: 1px solid #f2f2f2;
            overflow: scroll;
            position: relative;
        }

        .bss-product-option {
            position: relative;
            padding-top: 5px;

            .bss-fastorder-swatch {
                max-height: 190px;
                overflow: auto;
            }

            .reset-configurablegridview {
                position: absolute;
                top: 0;
            }

            > .field {
                > .label {
                    font-weight: 600;
                }
            }

            > .field.required {
                > .label {
                    &:after {
                        content: "*";
                        color: $color_1;
                        font-size: 1.2rem;
                        margin: 0 0 0 5px;
                    }
                }

                .legend {
                    &:after {
                        content: "*";
                        color: $color_1;
                        font-size: 1.2rem;
                        margin: 0 0 0 5px;
                    }
                }
            }

            .field.required {
                .legend {
                    font-weight: 600;
                    font-size: 1.4rem;
                    border: none;
                    display: inline-block;
                    float: none;
                    margin: 0 0 8px;
                    padding: 0;
                }
            }

            .bss-options-info {
                border-bottom: 1px solid #ccc;
                padding-bottom: 10px;
                margin-bottom: 10px;

                .legend {
                    font-weight: 600;
                    font-size: inherit;
                    border-bottom: none;
                }
            }
        }

        .bss-box-button {
            text-align: center;
            width: 100%;
            padding-bottom: 19px;
            margin-top: -2px;

            .fieldset {
                margin: 0 0 20px;
            }
        }

        .total-area {
            margin-top: 10px;

            .qty-total,
            .price-total {
                display: inline-block;
            }

            .price-total {
                display: inline-block;
                padding-left: 15px;
            }
        }
    }

    #bss-links-advice-container {
        display: none;
    }

    #bss-validation-message-box {
        display: none;
    }

    &.bss-configurable-grid-view-popup {
        width: 90% !important;
        padding: 0;
        max-height: 450px;
        box-sizing: border-box;
        max-width: 658px;
        #bss-fastorder-form-option {
            .bss-product-option {
                padding-top: 25px;
            }
        }

        .photo {
            max-height: 135px;
        }
    }

    .product-name {
        font-weight: bold;
    }

    .price-box {
        margin: 7px 0;
    }

    .actions {
        button.primary {
            width: auto;
            line-height: 1.6rem;
            padding: 7px 15px;
            margin: 0 10px;
            font-size: 1.4rem;
        }
    }

    .next-previous {
        text-decoration: none;
        display: inline-block;
        padding: 8px 9px;
        position: absolute;
    }

    .previous {
        top: 40%;
        left: 5%;
    }

    .next {
        top: 40%;
        right: 5%;
    }

    #closePopup {
        position: absolute;
        right: 35px;
        cursor: pointer;
        // .lib-font-size(20);
        color: #bfbfbf;
    }
}

.bss-fastorder-row-price {
    .price {
        @include mq($max-screen: $screen-m) {
            margin-top: $spacer--medium;
            font-size: $font-size-medium;
        }
    }
}
