$mega-menu__background-color: $gray;
//link
$mega-menu__link-color: $black;
$mega-menu__link-font-weight: $font-weight-bold;

@import 'mega-menu';

.mega-menu {
    &__list {
        justify-content: start;
    }

    &__item {
        &--pull-right {
            margin-left: auto;
        }
    }
}
