.button {
    &--link {
        font-weight: $font-weight-medium;
        &:hover,
        &.focus-visible {
            color: $gray-lighter;
            text-decoration: underline;
            background: $black;
        }
    }
    &--icon-black {
        $button__icon-secondary-background      : $black;
        $button__icon-secondary-background-hover: $white;

        background: $button__icon-secondary-background;
        .icon,
        .button__icon {
            fill: $button__icon-secondary-background-hover;
        }

        &:hover,
        &.focus-visible {
            background-color: $button__icon-secondary-background;
            &:before {
                background-color: $button__icon-secondary-background-hover;
            }
            .icon,
            .button__icon {
                fill: $button__icon-secondary-background;
            }
        }
    }
    &--icon-gray {
        $button__icon-secondary-background      : $gray-darker;
        $button__icon-secondary-background-hover: $gray-light;

        background: $button__icon-secondary-background;
        .icon,
        .button__icon {
            fill: $button__icon-secondary-background-hover;
        }

        &:hover,
        &.focus-visible {
            background-color: $button__icon-secondary-background;
            &:before {
                background-color: $button__icon-secondary-background-hover;
            }
            .icon,
            .button__icon {
                fill: $button__icon-secondary-background;
            }
        }
    }
    &.input__button-pass,
    &.quicksearch__close-button {
        position: absolute;
        background: none;
    }
}

.billingValidationModal {
    button[data-role="action"] {
        @extend .button;
    }
}
