.table {
    &--hidden-border {
        border-collapse: collapse;
        border-style: hidden;

        thead {
            th {
                position: relative;
                border-right: none;
                border-bottom: $table-border;

                &:not(:last-child)::after {
                    content: "";
                    height: 75%;
                    width: 1px;
                    right: -1px;
                    top: 50%;
                    transform: translateY(-50%);
                    background: $gray-light;
                    position: absolute;
                    z-index: $z-index-normal;
                }
            }
        }

        tfoot {
            td {
                border: none;

                @include mq($screen-m) {
                    padding: $spacer--large $spacer--medium;
                }
            }
        }

        .table__td {
            &--no-border-left {
                border-left: none;
            }

            &--no-border-right {
                border-right: none;
            }
        }

        .table__th {
            &--no-border {
                &:after {
                    content: none !important;
                }
            }
        }
    }
}
