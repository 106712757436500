.fast-order {
    &__toolbar {
        // display: flex;
        // hidden for now
        display: none;
        justify-content: flex-end;
        border: none;
        padding: $spacer $spacer--medium;

        @include mq($screen-m) {
            padding: $spacer;
        }
    }

    &__table {
        table-layout: fixed;
    }

    &__table-header {
        width: 25%;

        &--narrow {
            width: 15%;
        }

        &--wide {
            width: 30%;
        }

        &--widest {
            width: 35%;
        }
    }

    &__table-td-subtotal-summary {
        font-weight: $font-weight-bold;
    }

    &__actions {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;

        @include mq($screen-l) {
            flex-direction: row;
        }
    }

    &__actions-wrapper {
        width: 100%;
    }

    &__actions-input {
        flex-grow: 1;
        margin: 0;
        width: 320px;
        display: flex;
    }

    &__actions-button {
        min-width: 96px;
        padding: 0 $spacer--medium;
        align-self: flex-start;
    }

    &__quick-order {
        display: flex;
        margin: 0 0 $spacer;

        @include mq($screen-xl) {
            max-width: 460px;
        }
    }

    &__file {
        display: flex;
        flex-direction: row;
    }

    &__upload-input,
    &__addtocart-info {
        @include visually-hidden();
    }

    &__sorting-direction {
        display: none;
    }

    &__bottom {
        width: 100%;
    }

    @at-root .cms-page__content {
        .cms-quickorder & {
            max-width: 100%;
        }
    }

    &__checkout-actions {
        button {
            width: 100%;

            @include mq($screen-m) {
                width: max-content;
                margin-right: $spacer--medium;
            }
        }
    }

    &__quantity-update {
        display: flex;
        max-width: min-content;
        margin: auto;

        input {
            @include mq($max-screen: $screen-s) {
                width: 40px;
            }
        }
    }

    &__autocomplete {
        position: relative;
        top: -20px;
    }

    &__search-results {
        border: $border-width-base $border-style-base $black;
        background: $white;
        padding-top: $spacer--medium;
    }

    &__search-row-wrapper {
        position: relative;
    }

    &__table {
        tbody {
            tr {
                td {
                    &.fast-order__product-name {
                        padding: $spacer--medium 0;
                    }
                }

                @include mq($max-screen: $screen-m) {
                    display: grid;
                    grid-template-columns: 25% 25%;
                    border: none;

                    td {
                        grid-column-start: 1;
                        grid-column-end: 5;
                        width: 100%;
                        border-bottom: $border-base;
                        display: flex;
                        flex-direction: column;
                        padding: $spacer--medium $spacer;

                        &:nth-child(2) {
                            grid-column-start: 1;
                            grid-column-end: 3;
                            border-width: 0;
                        }

                        &:nth-child(3) {
                            grid-column-start: 3;
                            grid-column-end: 5;
                            border-width: 0;
                        }

                        &:nth-child(4) {
                            grid-column-start: 1;
                            grid-column-end: 5;
                            width: 100%;
                            border-width: 0 0 $border-width-base;
                        }

                        &:nth-child(5) {
                            grid-column-start: 1;
                            grid-column-end: 3;
                            border-right: $border-base;
                        }

                        &:nth-child(6) {
                            grid-column-start: 3;
                            grid-column-end: 5;
                        }

                        &::before {
                            margin-bottom: $spacer;
                        }

                        &:nth-child(3),
                        &:nth-child(4) {
                            display: flex;
                            justify-content: center;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        tfoot {
            td {
                background: $gray-lighter;
            }
        }
    }

    &__upload-btn,
    &__download-btn {
        @include mq($max-screen: $screen-m) {
            width: 100%;
        }
    }

    &__sorter {
        display: flex;
        align-items: center;

        @include mq($max-screen: $screen-m) {
            width: 100%;
            margin-top: $spacer--medium;
        }
    }

    &__grouped-product-name {
        width: 100%;

        @include mq($max-screen: $screen-m) {
            .price__value {
                font-size: $font-size-base;
            }
        }
    }

    &__row-actions {
        display: flex;
        justify-content: center;
        width: max-content;

        @include mq($max-screen: $screen-l, $min-screen: $screen-m) {
            position: absolute;
            bottom: 0;
            right: $spacer;
        }

        @include mq($screen-l) {
            margin: auto;
        }
    }

    &__row-action {
        .button {
            display: none;

            &:before {
                z-index: $z-index-lowest;
            }

            &__icon {
                margin: auto;
            }
        }
    }

    &__td-product-img {
        @include mq($max-screen: $screen-l, $min-screen: $screen-m) {
            padding-bottom: 50px;
        }
    }

    &__multi-show {
        @include mq($max-screen: $screen-l) {
            width: 100%;
        }
    }

    &__multi-show-textarea {
        min-height: 48px;
    }

    &__product-image {
        width: min-content;
        margin: auto;
    }

    &__product-image-wrapper {
        padding-bottom: 100%;
    }
}
