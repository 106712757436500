.page-print {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    #zonos {
        display: none !important;
    }
    .header__logo {
        width: 100%;
        max-width: 100%;
        background: $gray-darker;
        padding: 25px 20px;
        display: block;
        text-align: left;
    }
}