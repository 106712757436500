.toolbar {
    &__limiter {
        width: 100%;

        @include mq($screen-m) {
            width: initial;
        }
    }

    &__limiter-label {
        margin-right: $spacer--semi-medium;

        @include mq($screen-m) {
            margin-right: $spacer--small;
        }
    }
}
