.order-summary {
    .grand.totals {
        display: flex;
        justify-content: space-between;
    }
    .subtotal {
        display: flex;
        justify-content: flex-end;
    }
    .details-column {
        display: flex;
        flex-direction: column;
        &__links {
            display: flex;
        }
    }
}
