.side-menu {
    &__trigger {
        margin-right: $spacer--medium;
        .side-menu__trigger-icon {
            fill: $gray;
        }
    }
    &__container {
        max-height: calc(100vh - 82px);
        margin-top: 82px;
        padding: 0;
        @include mq($screen-l) {
            max-height: calc(100vh - 82px);
            margin-top: 82px;
        }
    }
    &__content {
        width: 100%;
        max-width: none;
        margin: 0;
    }
    &__close-button {
        top: -56px;
        left: 8px;
        @include mq($screen-l) {
            top: -56px;
            left: 8px;
        }
    }
    &__close-button-text.button__text {
        font-weight: $font-weight-bold;
        font-family: $font-family-base;
    }
}
.modal {
    &__close-button.button {
        position: absolute;
    }
}