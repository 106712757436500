// Colors
$red                         : #e2231a;
$green                       : #0e8a00;
$black                       : #000000;
$blue                        : #0075e4;
$seafoam-blue                : #77ccb9;

// Grayscale colors
$gray-darker                 : #232323;
$gray-dark                   : #6e7577;
$gray                        : #d8d8d8;
$gray-light                  : #eaeaea;
$gray-lighter                : #fbfbfb;

// Semantic color scheme
$color-primary: $gray-darker;
$color-secondary: $gray-dark;

// Typography
$font-weight-light: 200;

// Components
$border-base: 1px solid $black;
