.search-no-result {
    &.page-layout-1column {
        .catalog-list {
            display: flex;

            &__products {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &__sidebar-additional {
                display: none;
            }
        }
    }
}
