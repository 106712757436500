.success-page {
    &__registration-form {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: $spacer--large;
    }

    &__input {
        margin-bottom: 0;
        margin-right: $spacer--medium;
    }
}
