.dual-banner {
    display: flex;
    flex-wrap: wrap;

    .banner {
        width: 100%;

        @include mq($screen-m) {
            width: 50%;
        }
    }

    &--teaser {
        .banner {
            width: 100%;

            @include mq($screen-xl ) {
                width: 50%;
            }
        }
    }

    &--two-thirds {
        .banner {
            @include mq($screen-m) {
                width: 34%;

                &:first-of-type {
                    width: 66%;
                }
            }

            img.ratio-image {
                width: 100%;
            }
        }
    }

    &--with-space {
        @include mq($screen-m) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        .banner {
            @include mq($screen-m) {
                width: calc(34.4% - 8px);

                &:first-of-type {
                    width: calc(66% - 8px);
                    margin-right: 16px;
                }
            }

            img.ratio-image {
                width: 100%;
            }
        }
    }
}
