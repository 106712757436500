.shipping-methods {
    &--container {
        display: flex;
        flex-direction: column;
    }
    &__actions-toolbar {
        order: 2;
    }
    .order-attributes {
        order: 1;
    }
}
