$yardstore-header__text-color: $gray;

.header {
    &.header--checkout {
        background: $gray-darker;
    }
    &__brief-info {
        .brief-info__icon {
            fill: $yardstore-header__text-color;
        }
        .brief-info__title {
            color: $yardstore-header__text-color;
        }
        .brief-info__subtitle {
            color: $yardstore-header__text-color;
        }
    }
    &__minicart {
        .header-button {
            background: $header__background;
        }
        .header-button__icon {
            fill: $yardstore-header__text-color;
        }
    }
    > li.link.wishlist {
        display: none !important;
    }
}
@media only screen and (min-width: 768px) {
    .fieldset {
        >.field {
            margin-bottom: 10px;
        }
    }
    .modal-popup {
        .modal-header {
            padding-top: 1.2rem;
        }
        .modal-inner-wrap {
            margin: 2rem auto;
        }
    }
}