.category-image {
    display: none;
}
.apptrian-subcategories-grid {
    .apptrian-subcategories-category-image {
        img {
            padding: 0;
            border: none;
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .apptrian-subcategories-category {
        margin-top: 35px;
    }
}
.apptrian-subcategories-category-image {
    height: 200px;
    position: relative;
}
.apptrian-subcategories-category-name {
    > a {
        > span {
            font-weight: 400;
            color: $black;
            font-size: 15px;
        }
        position: relative;
        top: 10px;
    }
}
