.auth {
    &__link {
        &--registration,
        &--login {
            color: $gray-darker;

            &:hover,
            &.focus-visible {
                color: $white;
            }
        }
        &--active {
            &:hover,
            &.focus-visible {
                color: $gray-darker;
            }
        }
    }
}
.reset-pass-notice {
    background: yellow;
    padding: 5px;
    margin: 10px 0;
    > a {
        font-weight: bold;
        color: $red;
    }
}
.block.newsletter {
    .form.subscribe {
        display:none !important;
    }
}
