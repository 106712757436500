.order-attributes {
    textarea {
        width: 100%;
        padding: $spacer--medium;
        line-height: 1.7;
        &:focus-within {
            outline: 2px solid $black;
        }
    }
    label {
        font-size: $font-size-medium;
    }

    .file-uploader-area {
        @extend .file-upload;

        label {
            @extend .button;
            @extend .file-upload__button;
        }

        input[type='file'] {
            @extend .file-upload__input;
        }
    }

    .file-uploader-summary {
        display: flex;
        flex-wrap: wrap;

        .file-uploader-preview {
            width: 100%;
        }

        .file-uploader-filename {
            margin-right: $spacer;
        }

        .action-remove {
            @extend .button;
            @extend .button--secondary;
        }
    }
}
