.product-view {
    &__add-to-cart {
        .product-view__buttons & {
            display: block;

            .button__text {
                display: inline-block;
            }
        }
    }

    &__tab {
        img {
            max-width: max-content;
        }
    }

    .product-options {
        .price-notice {
            display: flex;
        }
    }
}

.catalog-product-view {
    #zonos {
        bottom: 120px;

        @include mq($screen-m) {
            bottom: 0;
        }
    }
}
