div[data-content-type=slider] {
    .slick-list > div,
    .slick-slide > div,
    .slick-track > div {
        display: block;
    }
}

[data-content-type=slide] {
    > [data-element=empty_link],
    > [data-element=link] {
        display: block;
    }
}