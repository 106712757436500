$color-changed: #93EE9E;
$color-gray80: #ccc;
$color-white-smoke: #f5f5f5;

.validateAddressForm,
.validateBinding,
.checkout-validate-address {
    .form.validate {
        display: flex;
        flex-wrap: wrap;

        .addressOption {
            display: flex;
            margin: 20px 10px 0 10px;
            flex: 1 0 auto;

            .addressLabel {
                flex: 1;
                cursor: pointer;
                display: block;
                padding: 10px 10px 10px 30px;
                left: -10px;
                position: relative;

                .optionTitle {
                    font-weight: bold;
                    font-size: 14px;
                    margin-bottom: 5px;
                }
            }

            .addressRadio {
                flex: 0;
                right: -10px;
                top: 10px;
                display: table;
                position: relative;
            }

            &.selected {
                outline: 1px solid $color-gray80;
                background: $color-white-smoke;
            }
        }
    }

    .legend {
        margin: 0;
        padding: 0;
        border-bottom: none;
        .step-title {
            padding-bottom: 15px;
        }
    }

    .validatedAddress {
        margin-top: 10px;
        padding: 10px;
        outline: 1px solid $color-gray80;
        background: $color-white-smoke;
    }

    .address-field-changed {
        text-decoration: none;
        background-color: $color-changed;
    }

    .errorMessageContainer {
        .instructions p,
        .originalAddressText {
            margin: 10px 0 ;
            padding: 10px;
            outline: 1px solid $color-gray80;
            background: $color-white-smoke;
        }
    }

    .instructions {
        margin-top: 10px;

        a:hover {
            cursor: pointer;
        }
    }
}

.validateAddressForm,
.validateBinding {
    display: none;
}

.multishipping-checkout {
    .form.validate {
        .addressOption {
            margin: 0 10px 10px 0;
        }
    }

}
