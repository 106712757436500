.banner {
    &--heading {
        .banner__heading {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 90px;
            color: $white;
            margin-bottom: 0;
            background-color: $red;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            font-weight: $font-weight-normal;
            text-transform: none;
            font-size: $font-size-large;

            @include mq($screen-m) {
                font-size: $font-size-extra-large;
            }
        }
    }

    &--square {
        .ratio-container .ratio-image {
            transition: $transition-base;
        }

        &:hover,
        &.focus-visible {
            .ratio-container .ratio-image {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }

        .banner {
            &__text {
                min-height: 48px;
            }

            &__content {
                padding-bottom: 0;
            }
        }
    }
}
