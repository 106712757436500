.cms-fast-order {
    .load.indicator {
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        position: absolute;
    }

    .load.indicator:before {
        background: transparent url("../images/loader-2.gif") no-repeat 50% 50%;
        border-radius: 5px;
        height: 160px;
        width: 160px;
        bottom: 0;
        box-sizing: border-box;
        content: "";
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
    }

    .load.indicator > span {
        display: none;
    }

    .loading-mask {
        bottom: 0;
        left: 0;
        margin: auto;
        position: fixed;
        right: 0;
        top: 0;
        //  to override amlocator.css
        z-index: 100 !important;
        background: rgba(0, 0, 0, 0.5);
    }

    .loading-mask .loader > img {
        bottom: 0;
        left: 0;
        margin: auto;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 100;
    }

    .loading-mask .loader > p {
        display: none;
    }
}
