.marketing-bar {
    &__close {
        &:before {
            background-color: $color-primary;
        }
        &:hover,
        &.focus-visible {
            background-color: $color-primary;
            .icon {
                fill: $gray-lighter;
            }
        }
    }
}
