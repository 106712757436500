.teaser {
    &--overlay {
        position: relative;
        overflow: hidden;

        .teaser {
            &__image {
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(1.2);
            }

            &__content {
                width: 100%;
                height: 100%;
                padding: 0;
                z-index: $z-index-low;
            }

            &__content-block {
                padding: $spacer--extra-large 0 $spacer--semi-medium;

                @include mq($screen-m) {
                    padding: 115px 0 $spacer--large;
                }

                .content-block {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    &__heading {
                        @extend .heading--first-level;
                        padding: 0;
                    }

                    &__description {
                        min-height: 60px;
                    }

                    @include mq($screen-m) {
                        align-items: flex-end;
                    }
                }

                & * {
                    color: white;
                }
            }

            &__button {
                margin-top: $spacer;

                &--primary {
                    background-color: $black;
                }

                &--secondary {
                    background-color: $red;
                }
            }
        }
    }

    &--overlay-primary {
        background-color: $red;
    }

    &--overlay-secondary {
        background-color: $black;
    }
}
