.font-roboto {
    font-family: Roboto;
}
.font-roboto-condensed {
    font-family: 'Roboto Condensed';
}
.text-xxl {
    font-size: $font-size-super-extra-large;
}
.text-xl {
    font-size: $font-size-extra-large;
}
.text-l {
    font-size: $font-size-large;
}
.text-m {
    font-size: $font-size-medium;
}
.text-n {
    font-size: $font-size-base;
}
.text-s {
    font-size: $font-size-small;
}
.text-xs {
    font-size: $font-size-extra-small;
}
.text-30 {
    font-size: 30px;
}
.font-normal {
    font-weight: normal;
}
.no-underline {
    text-decoration: none;
}
.hover-no-underline {
    &:hover {
        text-decoration: none;
    }
}
