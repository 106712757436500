.message {
    &--checkout {
        justify-content: flex-start;
        padding: $spacer--medium $spacer--semi-medium;
        font-size: $font-size-medium;
        border: $border-base;
        border-width: 2px;
        border-radius: 10px;
        background-color: $gray-light;
        max-width: 1120px;

        &::after {
            content: none;
        }
    }
}
