@font-face {
    font-weight: 400;
    font-family: Roboto;
    font-style: normal;
    font-display: swap;
    src:
    url('../fonts/roboto/regular/roboto-regular.woff2') format('woff2'),
    url('../fonts/roboto/regular/roboto-regular.woff') format('woff'),
    url('../fonts/roboto/regular/roboto-regular.ttf') format('ttf');
}

@font-face {
    font-weight: 500;
    font-family: Roboto;
    font-style: normal;
    font-display: swap;
    src:
    url('../fonts/roboto/bold/roboto-bold.woff2') format('woff2'),
    url('../fonts/roboto/bold/roboto-bold.woff') format('woff'),
    url('../fonts/roboto/bold/roboto-bold.ttf') format('ttf');
}

@font-face {
    font-weight: 700;
    font-family: Roboto;
    font-style: normal;
    font-display: swap;
    src:
    url('../fonts/roboto/black/roboto-black.woff2') format('woff2'),
    url('../fonts/roboto/black/roboto-black.woff') format('woff'),
    url('../fonts/roboto/black/roboto-black.ttf') format('ttf');
}

@font-face {
    font-weight: 700;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-display: swap;
    src:
    url('../fonts/roboto-condensed/bold/roboto-condensed-bold.woff2') format('woff2'),
    url('../fonts/roboto-condensed/bold/roboto-condensed-bold.woff') format('woff'),
    url('../fonts/roboto-condensed/bold/roboto-condensed-bold.ttf') format('ttf');
}
