.brief-info {
    display: block;

    &__icon-wrapper {
        .icon {
            width: 64px;
        }
    }

    &--dividers {
        border: $brief-info__border--dividers;
        border-width: $brief-info__border-width--dividers;

        .brief-info__item {
            @include mq($max-screen: $screen-m) {
                flex-direction: column;
                align-items: flex-start;
                padding: $spacer--large $spacer--semi-medium;
            }

            &:not(:last-child) {
                @include mq($max-screen: $screen-m) {
                    &:after {
                        height: 1px;
                        width: calc(100% - 2 * #{$spacer--medium});
                        top: 100%;
                        bottom: 0;
                        left: $spacer--medium;
                        right: $spacer--medium;
                    }
                }
            }
        }
    }
}
