.catalog-list {
    &__banner {
        margin-bottom: $spacer--extra-large;
    }

    &__categories {
        margin-bottom: 130px;
    }

    &__category-description {
        max-width: 256px;
        margin: auto;

        @include mq($screen-m) {
            max-width: 704px;
        }

        @include mq($screen-l) {
            max-width: 1104px;
        }
    }

    &__category-image-wrapper {
        padding-bottom: 105%;
    }

    &--category {
        display: flex;
        flex-flow: column nowrap;

        .catalog-grid {
            @include mq($screen-l) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    &__products {
        z-index: 1;
    }
}
