.cart {
    &__print-table {
        margin-top: $spacer--large;
        page-break-inside: auto;

        tr {
            page-break-inside: avoid;
            page-break-after: auto;

            &:last-child td {
                @include mq($screen-m) {
                    border-bottom: $border-width-base $border-style-base $gray-light;
                }
            }
        }

        th.description {
            width: 50%;
        }

        @include mq($max-screen: $screen-m) {
            border-width: 0;
        }

        @media print {
            border-width: 0;

            th {
                display: table-cell;
                padding: $table__th-padding\@medium;
                border-bottom: $table__th-border-bottom\@medium;
            }

            td {
                display: table-cell;
                padding: $table__td-padding\@medium;
                border-bottom: $table__td-border-bottom\@medium;

                &:before {
                    display: none;
                }
            }

            tr {
                display: table-row;
                padding: $table__tr-padding\@medium;
                border-bottom: $table__tr-border-bottom\@medium;
            }
        }
    }

    &__additional-info {
        margin-top: $spacer--large;

        .box {
            margin-bottom: $spacer--medium;
        }

        .box-content address {
            font-style: normal;
        }
    }

    &__print-section-title {
        font-size: $font-size-medium;
    }
}
.totals-tax {
    display: flex;
    justify-content: space-between;
}