#product-options-wrapper {
    margin-top: 15px;
    >.fieldset {
        >.field {
            >.label {
                >span {
                    font-size: 18px;
                }
            }
        }
    }
}
.product-custom-option {
    &.radio {
        vertical-align: middle;
        font-size: 20px;
    }
}

.product-view__main-actions {
    .product-view__add-to-cart:after {
        top: 20px;
    }
}